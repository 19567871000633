<script setup lang="ts">
import { cn } from '@laam/lib/utils';

const props = defineProps<{
	class?: string;
}>();
</script>
<template>
	<div :class="cn(' lg:bg-white lg:shadow-sm rounded-xl', props.class)">
		<slot />
	</div>
</template>
